import { Doctor } from "./../../../interfaces/doctor";
import { DoctorsService } from "./../../../shared-services/doctors.service";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { LoginService } from "./login.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToasterService } from "angular2-toaster";

import * as _ from "lodash";
import { environment } from "../../../../environments/environment";
import { StorageService } from "../../../shared-services/storage.service";
import { AccountService } from "../../../shared-services/account.service";
import { FormControl, FormGroup } from "@angular/forms";
import { User } from "../../../interfaces/user";

import { UtilsService } from "../../../shared-services/utils.service";
import swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public requestProcessing: boolean = false;
  public emailNotVerified: boolean = false;
  public authLogin: { username: string; password: string } = {
    username: "",
    password: "",
  };

  public queryParams: Params = {};
  public isLandingPage: boolean = false;
  public hidePassword: boolean = true;

  constructor(
    public http: HttpClient,
    public loginService: LoginService,
    public storage: StorageService,
    private router: Router,
    public toaster: ToasterService,
    private route: ActivatedRoute,
    private account: AccountService,
    private utils: UtilsService,
    private doctorsService: DoctorsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLandingPage = this.router["url"].split("?")[0] == "/";
    this.route.queryParams.subscribe((params: Params) => {
      this.queryParams = params;

      if (params.redirectParams)
        this.authLogin.username = JSON.parse(params.redirectParams).login;

      if (params.login) this.authLogin.username = params.login;

      if (params.pairingGuid && params.appDesc) {
        swal({
          title: "Povezava z ustanovo",
          html: `Za povezavo računov <b>${params.appDesc}</b> in doZdravnika se prijavite ali registrirajte.
          <br><br>
          Prijava je mogoča v primeru, da ste obstoječ uporabnik doZdravnika, v nasprotnem primeru se registrirajte.`,
          type: "warning",
        });
      }
    });
    const tmpUsername = this.storage.getShortCache("loginUsername");
    if (tmpUsername) {
      this.authLogin.username = tmpUsername;
    }
  }

  updateCurrentEmail() {
    this.storage.setShortCache("loginUsername", this.authLogin.username);
  }

  login(form: FormGroup): void {
    this.requestProcessing = true;

    _.mapValues(form.controls, (c: FormControl) => {
      c.markAsTouched();
    });

    if (
      form.invalid &&
      !(
        form["form"]["value"]["username"].length > 0 &&
        form["form"]["value"]["password"].length > 0
      )
    ) {
      this.requestProcessing = false;
      swal({
        title: "Vnesite podatke v vsa polja",
        html: "Prosimo, vnesite svoj elektronski naslov ter geslo.",
        type: "error",
        confirmButtonText: "Zapri",
        confirmButtonClass: "btn btn-info btn-pill",
        showCloseButton: true,
      });
      return;
    }

    this.loginService
      .login(this.authLogin.username, this.authLogin.password)
      .subscribe(
        (response) => {
          this.requestProcessing = false;

          this.route.queryParams.subscribe((params: any) => {
            let orgName = params.appDesc;
            if (params.pairingGuid && params.appDesc) {
              swal({
                title: "Povezava z ustanovo",
                html: `S potrditvijo povezave boste ustanovi <b>${orgName}</b> omogočili vpogled v informacije o datumih opravljenih pregledov in datumov ter zaporednih številk odmerkov cepljenja.
              Ustanovi ne bo omogočen vpogled v nobene druge vaše osebne podatke.`,
                type: "warning",
                confirmButtonText: "Potrdi",
                cancelButtonText: "Zavrni",
                showCancelButton: true,
              }).then((res) => {
                if (res.value) {
                  this.loginService
                    .pairUser(params.pairingGuid, response)
                    .subscribe(
                      (response: any) => {
                        swal({
                          title: "Povezava uspešna",
                          html: `1. korak uspešen<br>Uspešno ste dokončali povezavo računov med ${orgName} in doZdravnika.<br><br>
                    2. korak<br>
                    Navodila za naročanje na sistematski pregled ali cepljenje<br><br>

                    Za naročilo na sistematski pregled ali cepljenje se ponovno prijavite v študentski informacijski sistem in izberite<br>
                    VIS -> Moji podatki -> doZdravnika-> 'PRIJAVI SE'<br><br>
                    
                    Za ogled celotnega procesa naročanja na sistematski pregled si lahko ogledate podrobna <a href="https://dozdravnika.si/help/vis" target="_blank">navodila</a>.`,
                          type: "success",
                          allowOutsideClick: false,
                        }).then((res) => {
                          if (res.value) {
                            this.loginService.logout();
                            this.router.navigate(["/"]);
                          }
                        });
                      },
                      (err) => {
                        swal({
                          title: "Povezava ni uspela",
                          html: "Ponovno poskusite kasneje",
                          type: "error",
                          allowOutsideClick: false,
                        });
                      }
                    );
                }
              });
            }

            let addDoctorID = undefined;
            if (params.addDoctor) addDoctorID = params.addDoctor;
            if (params.redirectParams) {
              let rParams = JSON.parse(params.redirectParams);
              if (rParams.addDoctor) addDoctorID = rParams.addDoctor;
            }

            if (addDoctorID) {
              let doctor: Doctor = {
                desc: "",
                id: addDoctorID,
                locations: [],
                selectedId: null,
                type: "Doctor",
              };

              this.doctorsService
                .getUserDoctors()
                .subscribe((doctorsResponse: Doctor[]) => {
                  const existingDoctor = doctorsResponse.find(
                    (doc) => doc.id == doctor.id
                  );
                  if (!existingDoctor) {
                    this.doctorsService
                      .getDoctorByID(doctor)
                      .subscribe((doctorResponse: Doctor) => {
                        this.doctorsService
                          .addUserDoctors(doctorResponse)
                          .subscribe(() => {
                            this.doParams(params);
                            swal({
                              title: "Izvajalec dodan",
                              html: `Izvajalec je bil dodan med "Moje izvajalce".`,
                              type: "success",
                              confirmButtonText: "Zapri",
                              confirmButtonClass: "btn btn-info btn-pill",
                              showCloseButton: true,
                            }).then(() => {
                              this.cd.detectChanges();
                            });
                          });
                      });
                  } else this.doParams(params);
                });
            } else {
              this.doParams(params);
            }

            // this.utils.getStaticConfig().subscribe((config) => {
            //   if (config.customPopupNotification) {
            //     const intr = setInterval(() => {
            //       if (!swal.isVisible()) {
            //         clearInterval(intr);
            //         swal({
            //           title: config.customPopupNotification.title,
            //           html: config.customPopupNotification.content,
            //           type: config.customPopupNotification.type
            //             ? config.customPopupNotification.type
            //             : "info",
            //           confirmButtonText: "Zapri",
            //           showCancelButton: false,
            //           reverseButtons: true,
            //           showCloseButton: true,
            //         });
            //       }
            //     }, 2000);
            //   }
            // });
          });
        },
        (error) => {
          this.requestProcessing = false;
          if (error.status === 400) {
            if (error.error.error == "user_not_activated")
              swal({
                title: "Prijava ni mogoča",
                html:
                  "Vaš račun ni aktiviran. Ponovno preverite svojo elektronsko pošto ali " +
                  '<a href="/help/nemozna-prijava"> kliknite za pomoč. </a>',
                type: "error",
                confirmButtonText: "Zapri",
                confirmButtonClass: "btn btn-info btn-pill",
                showCloseButton: true,
              });
            else
              swal({
                title: "Naslov in geslo se ne ujemata",
                html:
                  "Vaš račun ali geslo je nepravilno. Če se ne spomnite svojega gesla, ga " +
                  '<a href="/account/password">  ponastavite zdaj. </a>',
                type: "error",
                confirmButtonText: "Zapri",
                confirmButtonClass: "btn btn-info btn-pill",
                showCloseButton: true,
              });
          }
          if (
            error.status === 401 &&
            form["form"]["value"]["username"].indexOf("@") > 0
          ) {
            swal({
              title: "Naslov ni registriran",
              html:
                "Elektronski naslov še ni registriran. <br/> Če ste se ravnokar registrirali, je potrebno pred prijavo v spletno rešitev doZdravnika potrditi elektronski naslov in aktivirati vaš uporabniški račun. <br/>" +
                '<a href="/help">  Preberite pomoč </a> ali' +
                '<a href="/account/register">  Ustvarite si nov račun </a>',
              type: "error",
              confirmButtonText: "Zapri",
              confirmButtonClass: "btn btn-info btn-pill",
              showCloseButton: true,
            });
          }
          if (
            error.status === 401 &&
            form["form"]["value"]["username"].indexOf("@") === -1
          ) {
            swal({
              title: "Naslov ni pravilen",
              html: "Elektronski naslov, ki ste ga vnesli, ni napisan v pravilni obliki. <br/> Prosimo, ponovno vnesite elektronski naslov.",
              type: "error",
              confirmButtonText: "Zapri",
              confirmButtonClass: "btn btn-info btn-pill",
              showCloseButton: true,
            });
          }
          if (
            form["form"]["value"]["username"].length === 0 &&
            form["form"]["value"]["password"].length === 0
          ) {
            swal({
              title: "Vnesite podatke",
              html: "Izpolnite vaš elektronski račun in geslo",
              type: "error",
              confirmButtonText: "Zapri",
              confirmButtonClass: "btn btn-info btn-pill",
              showCloseButton: true,
            });
          }
        }
      );
  }

  doParams(frozenParams) {
    let params = { ...frozenParams };
    if (params.redirect || params.redirectType) {
      let redirectString = params.redirect;
      let redirectParams = {};

      if (params.redirectParams) {
        redirectParams = JSON.parse(params.redirectParams);
      }

      if (redirectString === "tvw" || params.redirectType === "tvw") {
        this.account
          .getUser(this.authLogin.username)
          .subscribe((userResponse: User) => {
            this.storage.addKzzToCookies(userResponse.kzz);
            window.location.href =
              environment.tvwUrl +
              "?token=" +
              this.storage.getTokenData().accessToken;
          });
      } else {
        delete redirectParams["login"];
        this.router.navigate([redirectString], {
          queryParams: redirectParams,
        });
      }
    } else if (params.redirectParams) {
      let redirectParams = JSON.parse(params.redirectParams);
      if (redirectParams.redirectTo) {
        delete redirectParams["login"];
        this.router.navigate([redirectParams.redirectTo], {
          queryParams: {},
          replaceUrl: true,
        });
      } else if (this.isLandingPage) {
        delete params["login"];
        this.router.navigate(["g/dashboard"], {
          queryParams: params,
          replaceUrl: true,
        });
      }
    } else {
      if (this.isLandingPage) {
        delete params["login"];
        this.router.navigate(["g/dashboard"], {
          queryParams: params,
          replaceUrl: true,
        });
      } else {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";

        const url = this.router["url"].split("?")[0];
        this.router.navigate([url], {
          queryParams:
            params.s || params.code || params.question ? params : undefined,
        });
      }
    }
  }

  rerouteToQR() {
    window.location.href = "https://app.infonet.si/dozdravnika_qr/";
  }
}
