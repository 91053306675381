import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { Ng2Webstorage } from "ngx-webstorage";

import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { LandingComponent } from "./landing/landing.component";
import { AppRoutingModule } from "./app-routing.module";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { StorageService } from "./shared-services/storage.service";
import { UtilsService } from "./shared-services/utils.service";
import { AuthGuard } from "./shared-services/auth-guard.service";
import { ToasterService, ToasterModule } from "angular2-toaster";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { registerLocaleData } from "@angular/common";
import localeSl from "@angular/common/locales/sl";
import localeSlExtra from "@angular/common/locales/extra/sl";
import { AccountService } from "./shared-services/account.service";
import { environment } from "../environments/environment";
import { NotFoundComponent } from "./not-found/not-found.component";

// import {Angulartics2Module} from 'angulartics2';
// import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { CookieService } from "ngx-cookie-service";
import { SharedModule } from "./modules/shared/shared.module";
import { RecoverPasswordErrorModalComponent } from "./modules/public/recover-password/recover-password-error-modal.component";
import { RegistrationErrorModalComponent } from "./modules/public/registration/registration-error-modal.component";
import { LoginErrorModalComponent } from "./modules/shared/login/login-error-modal.component";

// import {StompRService} from '@stomp/ng2-stompjs';
import { SocketProviderService } from "./shared-services/socket-provider.service";
import { SocketService } from "./shared-services/socket.service";
import { DoctorsService } from "./shared-services/doctors.service";
import { LogService } from "./shared-services/log.service";
import { PrescriptionsService } from "./shared-services/prescriptions.service";

registerLocaleData(localeSl, "sl", localeSlExtra);

// registerLocaleData(localeSl, 'sl');

import { defineLocale } from "ngx-bootstrap/chronos";
import { slLocale } from "ngx-bootstrap/locale";
import { MailsService } from "./shared-services/mails.service";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { DocumentsService } from "./shared-services/documents.service";
import { SocketPrescriptionsService } from "./shared-services/socket-prescriptions.service";
import { SocketReservationsService } from "./shared-services/socket-reservations.service";
import { LayoutService } from "./shared-services/layout.service";
import { RouterExtService } from "./shared-services/router-ext.service";
import { ReferralsService } from "./shared-services/referrals.service";
import { SickListsService } from "./shared-services/sick-lists.service";

defineLocale("sl", slLocale);

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      const tokenString = sessionStorage.getItem("doZdravnika|tokenData");
      if (tokenString) {
        const tokenData = JSON.parse(tokenString);
        return tokenData.accessToken;
      }
      return null;
    },
    whitelistedDomains: environment.whitelist,
    blacklistedRoutes: [
      "dozdravnika.si/auth/oauth/token",
      "dozdravnika.si:443/auth/oauth/token",
      "http://dozdravnika.si/auth/oauth/token",
      "https://dozdravnika.si/auth/oauth/token",
      "http://dozdravnika.si:80/auth/oauth/token",
      "https://dozdravnika.si:443/auth/oauth/token",
      "/auth/oauth/token",

      "srcin-devjava/api/mediskop/auth/oauth/token",
      "srcin-devjava:443/api/mediskop/auth/oauth/token",
      "http://srcin-devjava/api/mediskop/auth/oauth/token",
      "https://srcin-devjava/api/mediskop/auth/oauth/token",
      "http://srcin-devjava:80/api/mediskop/auth/oauth/token",
      "https://srcin-devjava:443/api/mediskop/auth/oauth/token",
      "/api/mediskop/auth/oauth/token",
    ],
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotFoundComponent,
    RecoverPasswordErrorModalComponent,
    RegistrationErrorModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    FormsModule,
    HttpClientModule,
    Ng2Webstorage.forRoot({ prefix: "doZdravnika", caseSensitive: true }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToasterModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
    /*
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics], {
      developerMode: !environment.production,
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
      }
    }),
    */
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: "modal-content",
      confirmButtonClass: "btn btn-danger mx-2 my-1",
      cancelButtonClass: "btn btn-light mx-2 my-1",
      confirmButtonText: "Potrdi",
      cancelButtonText: "Prekliči",
      inputClass: "form-control",
      backdrop: "rgba(12,32,45, 0.8)",
    }),
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "sl" },
    StorageService,
    UtilsService,
    AuthGuard,
    ToasterService,
    AccountService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    CookieService,
    // StompRService,
    SocketPrescriptionsService,
    SocketReservationsService,
    /*{
      provide: SocketPrescriptionsService,
      useFactory: socketPrescriptionsServiceFactory,
    },
    {
      provide: SocketReservationsService,
      useFactory: socketReservationsServiceFactory,
    },*/
    SocketProviderService,
    SocketService,
    DoctorsService,
    LogService,
    AccountService,
    PrescriptionsService,
    MailsService,
    DocumentsService,
    LayoutService,
    RouterExtService,
    ReferralsService,
    SickListsService,
  ],
  entryComponents: [
    RecoverPasswordErrorModalComponent,
    RegistrationErrorModalComponent,
    LoginErrorModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
