export const environment = {
  production: true,
  enableServiceWorkers: false,
  whitelist: ["dozdravnika.si", "https://dozdravnika.si", "dozdravnika.si:443"],

  apiServer: "https://dozdravnika.si",
  apiPort: 443,
  apiPath: "/api",

  authServer: "https://dozdravnika.si",
  authApiPort: 443,
  authApiPath: "/auth/oauth/token",
  authUsername: "test",
  authPassword: "test",

  socketServer: "https://dozdravnika.si",
  socketPort: 443,
  socketPath: "/healthapi",
  socketToken: "access_token",

  // instProvPath: "/inst-prov",
  // doctorPath: "/registry-inst-prov",
  // selectedDoctorsPath: "registration/user/selected-users",

  publicApiPort: 443,
  publicApiPath: "/registration",

  tvwUrl: "http://10.50.40.212:4200/waiting-list",
  v1Url: "https://dozdravnika.si/v1/",
  version: require("../../package.json").version,
  stage: "production",

  prescriptionsSocketServer: "https://dozdravnika.si",
  prescriptionsSocketPort: 443,
  prescriptionsSocketPath: "/meddochealthapi",
  prescriptionsSocketToken: "access_token",

  prescriptionsServer: "https://dozdravnika.si",
  prescriptionsPort: 443,
  prescriptionsPath: "/meddocapi",

  documentsServer: "https://dozdravnika.si",
  documentsPort: 443,
  documentsPath: "/documents-api",

  modulesApiPath: "/registry-modules",
  relativesApiPath: "/users_allowed",
  settingsApiPath: "/settings",

  messagesServer: "https://dozdravnika.si",
  messagesPort: 443,
  messagesPath: "/messagesapi",

  eulaTimeStamp: "https://dozdravnika.si/splosni-pogoji/timestamp.json",
  // staticConfig: "https://dozdravnika.si/config/config.json",
};
