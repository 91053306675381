import {Component} from '@angular/core';
import {Toast} from 'angular2-toaster';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-login-error-modal',
  template: `
    <ng-container *ngIf="data.error === 400">
      <span i18n>Elektronski naslov ({{data.email}}) in geslo se ne ujemata.<br/><br/>
        Ste pozabili svoje geslo?<br/></span><a routerLink="/account/password"><u
      i18n="@@loginErrorToasterRequestNewPassword">Zahtevajte novo geslo</u></a>
    </ng-container>
    <ng-container *ngIf="data.error === 401 && data.email.indexOf('@') > 0">
      <span i18n>Elektronski naslov ({{data.email}}) še ni registriran.<br/><br/></span>
      <span i18n>Če ste se ravnokar registrirali, je potrebno pred prijavo v spletno rešitev doZdravnika potrditi elektronski naslov in aktivirati vaš uporabniški račun. </span>
      <a routerLink="/help"><u i18n="@@loginErrorToasterHelp">Preberi pomoč</u></a><br/><br/>
      <a routerLink="/account/register" [queryParams]="queryParams"><u i18n="@@loginErrorToasterRegister">Ustvarite si nov račun</u></a>
    </ng-container>
    <ng-container *ngIf="data.error === 401 && data.email.indexOf('@') === -1">
      <span i18n>Elektronski naslov, ki ste ga vnesli, ni napisan v pravilni obliki.<br/><br/></span>
      <span i18n>Prosimo, ponovno vnesite elektronski naslov.</span>
    </ng-container>
  `,
  styles: []
})
export class LoginErrorModalComponent {

  public toast: Toast;
  public queryParams;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      this.queryParams = params;
    });
  }

  get data(): any {
    return (this.toast as any).data;
  }

}
