var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { combineLatest, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
var DoctorsService = /** @class */ (function () {
    function DoctorsService(http, utils, storage) {
        this.http = http;
        this.utils = utils;
        this.storage = storage;
        this.permissionsCache = [];
    }
    DoctorsService.prototype.searchDoctors = function (query) {
        return this.http.get(this.utils.getServerUrl() + "/search", {
            observe: "response",
            params: {
                param: query,
            },
            headers: this.utils.getHeader(this.storage.getTokenData()),
        });
    };
    DoctorsService.prototype.getDoctor = function (doctorId, locationId) {
        if (locationId === void 0) { locationId = null; }
        if (doctorId === null && locationId) {
            return this.http.get(this.utils.getServerUrl() + "/doctors").pipe(map(function (doctors) {
                var e_1, _a, e_2, _b;
                var foundLocation = null;
                try {
                    for (var doctors_1 = __values(doctors), doctors_1_1 = doctors_1.next(); !doctors_1_1.done; doctors_1_1 = doctors_1.next()) {
                        var doctor = doctors_1_1.value;
                        try {
                            for (var _c = __values(doctor.locations), _d = _c.next(); !_d.done; _d = _c.next()) {
                                var location_1 = _d.value;
                                if (location_1.id === locationId) {
                                    foundLocation = location_1;
                                    break;
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        if (foundLocation) {
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (doctors_1_1 && !doctors_1_1.done && (_a = doctors_1.return)) _a.call(doctors_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return {
                    desc: "",
                    id: "00000",
                    locations: [foundLocation],
                    type: "Doctor",
                };
            }));
        }
        return this.http.get(this.utils.getServerUrl() + "/doctors/" + doctorId, {
            headers: this.utils.getHeader(this.storage.getTokenData()),
        });
    };
    DoctorsService.prototype.getUserDoctors = function () {
        return this.http.get(this.utils.getServerUrl() + "/doctors/", {
            headers: this.utils.getHeader(this.storage.getTokenData()),
        });
    };
    DoctorsService.prototype.getDoctorByID = function (doctor) {
        return this.http.get(this.utils.getServerUrl() + "/doctors/" + doctor.id, {
            headers: this.utils.getHeader(this.storage.getTokenData()),
        });
    };
    DoctorsService.prototype.addUserDoctors = function (doctor) {
        var doctorObj = doctor;
        doctorObj.locations[0].activities = [];
        return this.http.post(this.utils.getServerUrl() + "/doctors/", doctorObj, {
            observe: "response",
            headers: this.utils.getHeader(this.storage.getTokenData()),
        });
    };
    DoctorsService.prototype.removeUserDoctor = function (doctorId) {
        return this.http.delete(this.utils.getServerUrl() + ("/doctors/" + doctorId), {
            observe: "response",
            headers: this.utils.getHeader(this.storage.getTokenData()),
        });
    };
    DoctorsService.prototype.getInstitutionConfig = function (institutionId) {
        return this.http.get(this.utils.getPublicServerUrl() + ("/org/config/" + institutionId + "/config"), { headers: this.utils.getHeader(this.storage.getTokenData()) });
    };
    DoctorsService.prototype.getModulePermission = function (module, institutionId, doctorId) {
        var _this = this;
        if (doctorId === void 0) { doctorId = ""; }
        var cachedItem = this.permissionsCache.find(function (item) {
            return (item.module === module &&
                item.institutionId === institutionId &&
                item.doctorId === doctorId);
        });
        if (cachedItem) {
            return of(cachedItem.enabled);
        }
        return this.http
            .get(this.utils.getModulesServerUrl() +
            ("/" + module + "/enabledFor/" + institutionId + "/" + doctorId), { headers: this.utils.getHeader(this.storage.getTokenData()) })
            .pipe(catchError(function (e) {
            if (module === "ENAR") {
                return of({ enabled: true });
            }
            else {
                return of({ enabled: false });
            }
        }), tap(function (response) {
            _this.permissionsCache.push({
                module: module,
                institutionId: institutionId,
                doctorId: doctorId,
                enabled: response,
            });
        }));
    };
    DoctorsService.prototype.getModulesPermissions = function (institutionId, doctorId) {
        var _this = this;
        if (doctorId === void 0) { doctorId = null; }
        var requests = [];
        ["ENAR", "ZDR", "EBOL", "EREF"].forEach(function (item) {
            requests.push(_this.getModulePermission(item, institutionId, doctorId).pipe(map(function (response) {
                return {
                    module: item,
                    enabled: response,
                };
            })));
        });
        return combineLatest(requests).pipe(map(function (response) {
            var newResponse = {};
            var any = false;
            response.forEach(function (item) {
                newResponse[item.module] = item.enabled;
                if (item.enabled) {
                    any = true;
                }
            });
            newResponse["any"] = any;
            return newResponse;
        }));
    };
    return DoctorsService;
}());
export { DoctorsService };
