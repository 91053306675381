<div *ngIf="emailNotVerified" class="alert alert-danger">
  <span class="pull-left" i18n="@@loginAccountNotActivated">Vaš račun še ni bil aktiviran. Navodila bi morali prejeti po elektronski pošti</span>
  <button ng-click="authLogin.openRouteWithRedirectParams('account.resendEmail', { username: authLogin.username })"
          class="btn ewc-btn-infonet-reverse ewc-push-up10" i18n="@@loginAccountResendActivationLink">
    Ponovno pošlji
  </button>
</div>

<form class="inner-width custom-form" novalidate (ngSubmit)="login(loginForm)" #loginForm="ngForm">
  <div class="form-group">

    <label i18n="@@loginEmail">Elektronski naslov</label>

    <div>
      <!-- <i class="las la-envelope"></i> -->
      <input
        type="email"
        name="username"
        [(ngModel)]="authLogin.username"
        #username="ngModel"
        class="form-control input"
        autofocus
        required
        (keyup)="updateCurrentEmail()"
        autocomplete="username"
        email>
    </div>


  </div>

  <div class="form-group">

    <label i18n="@@loginPassword">Geslo</label>

    <div class="inner-addon right-addon">
      <i class="las password-pointer" *ngIf="authLogin.password.length > 0" [ngClass]="hidePassword ? 'la-eye-slash' : 'la-eye'" (click)="hidePassword = !hidePassword"></i>
      <input
        [type]="hidePassword ? 'password' : 'text'"
        name="password"
        #password="ngModel"
        [(ngModel)]="authLogin.password"
        class="form-control input"
        autocomplete="current-password"
        required>
    </div>
  </div>

  <a routerLink="/account/password" [queryParams]="queryParams" class="forgot-password float-right" i18n="@@loginForgotPassword">
    Pozabljeno geslo?
  </a>

  <button class="btn btn-block ewc-big-btn-infonet"
          ng-disabled="authLogin.requestProcessing"
          type="submit">
    <i *ngIf="requestProcessing" class="fas fa-spinner fa-spin"></i>
    <span i18n="@@loginLoginButton">Prijava</span>
  </button>
</form>

<div *ngIf="isLandingPage">
  
  <div class="divider">
    <span class="divider-line"></span>
    <span class="divider-content">
      Nimate računa? Ustvarite ga.
    </span>
    <span class="divider-line"></span>
  </div>
  <button class="btn btn-block mt-4 mb-5 ewc-big-btn-infonet-reverse inner-width" type="button" routerLink="/account/register" [queryParams]="queryParams" ng-disabled="authLogin.requestProcessing">
    Registracija
  </button>

  <!-- <button class="btn btn-block mt-4 mb-5 ewc-big-btn-infonet-reverse inner-width" type="button" (click)="rerouteToQR()">
    Vnos podatkov za <b>covid potrdilo</b>
  </button> -->
</div>