var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SocketProviderService } from './socket-provider.service';
import { StompConfig } from '@stomp/ng2-stompjs';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';
import { finalize, share } from 'rxjs/operators';
import { SocketReservationsService } from './socket-reservations.service';
import { SocketPrescriptionsService } from './socket-prescriptions.service';
var SocketService = /** @class */ (function () {
    function SocketService(socketProvider, socketPrescriptionsProvider, stompService, prescriptionsStompService, storage) {
        this.socketProvider = socketProvider;
        this.socketPrescriptionsProvider = socketPrescriptionsProvider;
        this.stompService = stompService;
        this.prescriptionsStompService = prescriptionsStompService;
        this.storage = storage;
        this.commandObservables = [];
        this.stompConfig = {
            url: '',
            headers: {
                username: 'guest',
                password: 'guest'
            },
            heartbeat_in: 0,
            heartbeat_out: 20000,
            reconnect_delay: 5000,
            debug: !environment.production
        };
        this.stompPrescriptionsConfig = {
            url: '',
            headers: {
                username: 'guest',
                password: 'guest'
            },
            heartbeat_in: 0,
            heartbeat_out: 20000,
            reconnect_delay: 5000,
            debug: !environment.production
        };
    }
    SocketService.prototype.initSocket = function () {
        this.initDefaultSocket();
        this.initPrescriptionsSocket();
    };
    SocketService.prototype.initDefaultSocket = function () {
        var urlProvider = this.socketProvider.getProvider();
        if (urlProvider) {
            this.stompConfig.url = urlProvider;
            this.stompService.config = this.stompConfig;
            this.stompService.initAndConnect();
            // let timeout = 20;
            // let checkConnection = setInterval(()=>{
            //   if(this.stompService.connected() || timeout == 0){
            //     clearInterval(checkConnection);
            //     this.storage.clearAll();
            //     this.disconnect();
            //   }
            //   else {
            //     timeout--;
            //   }
            // }, 100);
            // this.initSubscription('', this.stompService);
        }
    };
    SocketService.prototype.initPrescriptionsSocket = function () {
        var urlProvider = this.socketPrescriptionsProvider.getProvider({
            socketServer: environment.prescriptionsSocketServer,
            socketPort: environment.prescriptionsSocketPort,
            socketPath: environment.prescriptionsSocketPath,
            socketToken: environment.prescriptionsSocketToken,
        });
        if (urlProvider) {
            this.stompPrescriptionsConfig.url = urlProvider;
            this.prescriptionsStompService.config = this.stompPrescriptionsConfig;
            this.prescriptionsStompService.initAndConnect();
            // let timeout = 20;
            // let checkConnection = setInterval(()=>{
            //   console.log(this.prescriptionsStompService.connected());
            //   if(this.prescriptionsStompService.connected() || timeout == 0){
            //     clearInterval(checkConnection);
            //     this.storage.clearAll();
            //     this.disconnect();
            //   }
            //   else {
            //     timeout--;
            //   }
            // }, 100);
            // this.initSubscription('', this.prescriptionsStompService);
        }
    };
    SocketService.prototype.isConnected = function () {
        return this.prescriptionsStompService.connected() && this.stompService.connected();
    };
    SocketService.prototype.initSubscription = function (hash, connection) {
        var _this = this;
        if (hash === void 0) { hash = ''; }
        var guid = this.storage.getUserGUID();
        if (hash) {
            guid += '-' + hash;
        }
        var stompSubscription = connection
            .subscribe('/queue/' + guid)
            .map(function (message) {
            var msg = JSON.parse(message.body);
            msg = msg[0];
            if (msg.payload) {
                var decoded = msg.payload;
                try {
                    decoded = decodeURI(msg.payload);
                }
                catch (e) {
                    console.log(e);
                }
                msg.payload = JSON.parse(decoded);
            }
            return msg;
        });
        if (hash) {
            return stompSubscription;
        }
        else {
            stompSubscription.subscribe(function (results) {
                var commandName = results.resultHeader.commandName;
                if (hash) {
                    commandName += '-' + hash;
                }
                if (_this.commandObservables.hasOwnProperty(commandName)) {
                    _this.commandObservables[commandName].next(results);
                }
            });
        }
    };
    SocketService.prototype.disconnect = function () {
        this.stompService.disconnect();
        this.prescriptionsStompService.disconnect();
    };
    SocketService.prototype.get = function (commandName, hash) {
        if (hash === void 0) { hash = ''; }
        if (hash) {
            commandName += '-' + hash;
        }
        if (!this.commandObservables.hasOwnProperty(commandName)) {
            var newObservable = new Subject();
            newObservable.pipe(finalize(function () {
            }), share());
            this.commandObservables[commandName] = newObservable;
        }
        return this.commandObservables[commandName];
    };
    SocketService.prototype.request = function (commandName, message, header, path, isolate, connection) {
        if (isolate === void 0) { isolate = false; }
        if (!connection) {
            connection = this.stompService;
        }
        var hash, subscription;
        if (isolate) {
            hash = new Date().getTime().toString();
            subscription = this.initSubscription(hash, connection);
        }
        else {
            subscription = this.get(commandName, hash);
        }
        if (message == null) {
            message = {};
        }
        this.sendMessage(connection, commandName, commandName, message, header, path, null, hash);
        return subscription;
    };
    SocketService.prototype.sendMessage = function (connection, commandId, commandName, message, header, path, user, hash) {
        if (hash === void 0) { hash = ''; }
        if (message == null) {
            message = {};
        }
        var sessionId = this.storage.getUserGUID();
        if (hash) {
            sessionId += '-' + hash;
        }
        var commandHeader = {
            commandId: commandId,
            commandName: commandName,
            sessionId: sessionId,
            destinations: [],
            user: user ? user : this.storage.getUserData(),
            cmdTime: Date.now()
        };
        if (header) {
            commandHeader = _.merge(commandHeader, header);
        }
        var payload = JSON.stringify(message);
        var commandMessage = {
            commandHeader: commandHeader,
            payload: payload
        };
        var pathString = '/app';
        if (path) {
            pathString += '/' + path;
        }
        connection.publish(pathString, JSON.stringify(commandMessage));
    };
    SocketService.prototype.requestOrders = function () {
        return this.request('getOrders');
    };
    SocketService.prototype.getDoctorFreeSlots = function (doctorId, locations, activity, dateFrom, appId) {
        if (typeof locations === 'string') {
            locations = [locations];
        }
        var message = {
            dateFrom: dateFrom,
        };
        if (doctorId && (!activity || !activity.vzsId)) {
            message.doctorId = doctorId;
        }
        if (activity) {
            if (activity.vzsId) {
                message.vzs = activity.vzsId;
            }
            else if (activity.id) {
                message.activityCode = activity.id;
            }
            else if (typeof activity == 'string') {
                message.activityCode = activity;
            }
        }
        if (appId)
            message.appId = appId;
        return this.request('getDoctorFreeSlots', message, {
            destinations: locations
        }, 'getDoctorFreeSlots', true);
    };
    SocketService.prototype.getDoctorfirstFreeSlots = function (doctorId, locations, activity, dateFrom, appId) {
        if (typeof locations === 'string') {
            locations = [locations];
        }
        return this.request('getDoctorFirstFreeSlots', {
            doctorId: doctorId,
            dateFrom: dateFrom,
            activityCode: activity,
            appId: appId
        }, {
            destinations: locations
        }, 'getDoctorFirstFreeSlots', true);
    };
    SocketService.prototype.createReservation = function (location, scheduleId, dateFrom, unitId, doctorId, activity, duration) {
        if (duration === void 0) { duration = undefined; }
        var message = {
            scheduleId: scheduleId,
            dateFrom: dateFrom,
            unitId: unitId,
            doctorId: doctorId,
        };
        if (activity) {
            if (activity.vzsId) {
                message = __assign({
                    vzs: activity.vzsId,
                    isVideoCall: activity.isVideoCall ? activity.isVideoCall : false
                }, message);
            }
            else {
                message = __assign({
                    selectedActivityCode: activity.id,
                    selectedActivityDescription: activity.desc,
                    isVideoCall: activity.isVideoCall ? activity.isVideoCall : false
                }, message);
            }
        }
        if (duration) {
            message = __assign({
                reservationDuration: duration
            }, message);
        }
        return this.request('createReservation', message, {
            destinations: [location]
        }, 'createReservation', true);
    };
    SocketService.prototype.confirmReservation = function (prereservationId, location, comment, documentIds) {
        if (comment === void 0) { comment = ''; }
        if (documentIds === void 0) { documentIds = []; }
        return this.request('confirmReservation', {
            prereservationId: prereservationId,
            comment: comment,
            documentIds: documentIds
        }, {
            destinations: [location]
        }, 'confirmReservation', true);
    };
    SocketService.prototype.createWaitingList = function (doctor, unit, activity, location, comment) {
        if (comment === void 0) { comment = ''; }
        return this.request('waitingListCreated', {
            doctorId: null,
            doctorDesc: null,
            unitId: null,
            unitDesc: null,
            selectedActivityCode: activity.id,
            selectedActivityDescription: activity.desc,
            comment: comment
        }, {
            destinations: [location]
        }, 'waitingListCreated', true);
    };
    SocketService.prototype.cancelReservation = function (message, header) {
        return this.request('cancelReservation', message, header, 'cancelReservation', true);
    };
    SocketService.prototype.requestMedications = function (locations, user, doctorId) {
        if (typeof locations === 'string') {
            locations = [locations];
        }
        return this.request('getMedicationsForPatient', {
            user: user,
            doctorId: doctorId,
        }, {
            destinations: locations,
        }, 'getMedicationsForPatient', true, this.prescriptionsStompService);
    };
    return SocketService;
}());
export { SocketService };
