import {Injectable} from '@angular/core';
import * as SockJS from 'sockjs-client';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';

@Injectable()
export class SocketProviderService {

  constructor(private storage: StorageService) {
  }

  getProvider(env?: any) {

    if (!env) {
      env = {};
      env.socketServer = environment.socketServer;
      env.socketPort = environment.socketPort;
      env.socketPath = environment.socketPath;
      env.socketToken = environment.socketToken;
    }

    const tokenData = this.storage.getTokenData();

    if (tokenData) {
      // http://localhost:8080/prescription?access_token=);
      // http://srcin-devjava:9000/healthapi?authorization=
      const socketUrl = `${env.socketServer}:${env.socketPort}\
      ${env.socketPath}\
      ?${env.socketToken}=${tokenData.accessToken}`
        .replace(/\s/g, '');

      return new SockJS(socketUrl);
    } else {
      return null;
    }
  }
}
