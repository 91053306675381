<div class="modal-body text-center">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>

  <i class="fas fa-clock text-primary fa-5x mt-5 mb-3"></i>
  <p class="modal-card-text">
    <span class="text-primary d-block">Pregl Barbara, dr. Med.</span>
    <span class="text-secondary">{{reservation.orgName}}</span>
  </p>

  <div class="jumbotron p-3 mt-3 mb-3">
    Termin:
    <h5 class="modal-title text-ucfirst">{{reservation.dateFrom | date : 'EEEE, d.M.yyyy \'ob\' HH:mm'}}</h5>
  </div>

  <div class="mt-3 mb-3">
    <dl>
      <dt class="upper-case" *ngIf="reservation.activityDescription">
        <small><strong>Storitev:</strong></small>
      </dt>
      <dd>{{reservation.activityDescription}}</dd>

      <dt class="upper-case" *ngIf="reservation.comment">
        <small><strong>Komentar:</strong></small>
      </dt>
      <dd>{{reservation.comment}}</dd>

      <dt class="upper-case">
        <small><strong>Status:</strong></small>
      </dt>
      <dd>
        <div class="status" [ngClass]="'status-'+statuses.reservations[reservation.status].color">
          <span>{{statuses.reservations[reservation.status].name}}</span>
        </div>
      </dd>
    </dl>
  </div>

</div>
<!--
<div class="modal-footer">
  <button class="card-button btn btn-outline-secondary btn-sm btn-reveal btn-round">
    <i class="far fa-calendar-plus fa-fw"></i> <span>Dodajte v koledar</span>
  </button>

  <a class="card-button btn btn-outline-info btn-sm btn-reveal btn-round"
     [href]="getGoogleCalendarUrl(reservation)" target="_blank">
    <i class="fab fa-google fa-fw"></i> <span>Dodajte v Google koledar</span>
  </a>
  <button class="card-button btn btn-outline-danger btn-sm btn-reveal btn-round" *ngIf="reservation.status === 1"
          (click)="showModalCancelReservation(reservation)">
    <i class="fa fa-times fa-fw"></i> <span>Odpovejte termin</span>
  </button>
</div>
-->
