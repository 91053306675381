<div class="container">
  <div class="card card-container">
    <p id="profile-name" class="text-center">
      <a routerLink="/" [queryParams]="queryParams">
        <svg height="76" width="300" class="center-block landing-logo">
          <image xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="assets/img/logo/doZdravnika.svg"
                 src="assets/img/logo/doZdravnika.png"
                 height="76"
                 width="300">
          </image>
        </svg>
      </a>
    </p>

    <h3 class="text-center ewc-bold-text" i18n="@@404Title">Stran ne obstaja</h3>
    <p class="text-center mt-5">
      <a routerLink="/" i18n="@@404BackToLandind">Nazaj na prvo stran</a>
    </p>

  </div>
</div>
