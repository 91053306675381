import { StorageService } from "./storage.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { Observable } from "rxjs/Observable";
import { Doctor } from "../interfaces/doctor";
import { combineLatest, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable()
export class DoctorsService {
  permissionsCache = [];

  constructor(
    public http: HttpClient,
    public utils: UtilsService,
    private storage: StorageService
  ) {}

  searchDoctors(query: string): Observable<HttpResponse<Doctor[]>> {
    return this.http.get(this.utils.getServerUrl() + "/search", {
      observe: "response",
      params: {
        param: query,
      },
      headers: this.utils.getHeader(this.storage.getTokenData()),
    }) as Observable<HttpResponse<Doctor[]>>;
  }

  getDoctor(doctorId: string, locationId: string = null): Observable<Doctor> {
    if (doctorId === null && locationId) {
      return this.http.get(this.utils.getServerUrl() + "/doctors").pipe(
        map((doctors: Doctor[]) => {
          let foundLocation = null;
          for (const doctor of doctors) {
            for (const location of doctor.locations) {
              if (location.id === locationId) {
                foundLocation = location;
                break;
              }
            }
            if (foundLocation) {
              break;
            }
          }
          return {
            desc: "",
            id: "00000",
            locations: [foundLocation],
            type: "Doctor",
          };
        })
      ) as Observable<Doctor>;
    }
    return this.http.get(this.utils.getServerUrl() + "/doctors/" + doctorId, {
      headers: this.utils.getHeader(this.storage.getTokenData()),
    }) as Observable<Doctor>;
  }

  getUserDoctors(): Observable<Doctor[]> {
    return this.http.get(this.utils.getServerUrl() + "/doctors/", {
      headers: this.utils.getHeader(this.storage.getTokenData()),
    }) as Observable<Doctor[]>;
  }

  getDoctorByID(doctor: Doctor): Observable<Doctor> {
    return this.http.get(this.utils.getServerUrl() + "/doctors/" + doctor.id, {
      headers: this.utils.getHeader(this.storage.getTokenData()),
    }) as Observable<Doctor>;
  }

  addUserDoctors(doctor: Doctor): Observable<HttpResponse<any>> {
    const doctorObj = doctor;
    doctorObj.locations[0].activities = [];
    return this.http.post(this.utils.getServerUrl() + `/doctors/`, doctorObj, {
      observe: "response",
      headers: this.utils.getHeader(this.storage.getTokenData()),
    }) as Observable<HttpResponse<any>>;
  }

  removeUserDoctor(doctorId: string): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.utils.getServerUrl() + `/doctors/${doctorId}`,
      {
        observe: "response",
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<HttpResponse<any>>;
  }

  getInstitutionConfig(institutionId: string) {
    return this.http.get(
      this.utils.getPublicServerUrl() + `/org/config/${institutionId}/config`,
      { headers: this.utils.getHeader(this.storage.getTokenData()) }
    );
  }

  getModulePermission(
    module: string,
    institutionId: string,
    doctorId: string = ""
  ): Observable<boolean> {
    const cachedItem = this.permissionsCache.find((item) => {
      return (
        item.module === module &&
        item.institutionId === institutionId &&
        item.doctorId === doctorId
      );
    });

    if (cachedItem) {
      return of(cachedItem.enabled);
    }

    return this.http
      .get(
        this.utils.getModulesServerUrl() +
          `/${module}/enabledFor/${institutionId}/${doctorId}`,
        { headers: this.utils.getHeader(this.storage.getTokenData()) }
      )
      .pipe(
        catchError((e) => {
          if (module === "ENAR") {
            return of({ enabled: true });
          } else {
            return of({ enabled: false });
          }
        }),
        tap((response) => {
          this.permissionsCache.push({
            module: module,
            institutionId: institutionId,
            doctorId: doctorId,
            enabled: response,
          });
        })
      ) as Observable<boolean>;
  }

  getModulesPermissions(
    institutionId: string,
    doctorId: string = null
  ): Observable<{}> {
    const requests = [];
    ["ENAR", "ZDR", "EBOL", "EREF"].forEach((item) => {
      requests.push(
        this.getModulePermission(item, institutionId, doctorId).pipe(
          map((response) => {
            return {
              module: item,
              enabled: response,
            };
          })
        )
      );
    });
    return combineLatest(requests).pipe(
      map((response) => {
        const newResponse = {};
        let any = false;
        response.forEach((item: any) => {
          newResponse[item.module] = item.enabled;
          if (item.enabled) {
            any = true;
          }
        });
        newResponse["any"] = any;
        return newResponse;
      })
    );
  }
}
