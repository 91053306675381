var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { ActivatedRoute, Router } from '@angular/router';
import * as urlParse from 'url-parse';
import { LoginService } from './modules/shared/login/login.service';
import { SocketService } from './shared-services/socket.service';
import { LayoutService } from './shared-services/layout.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(route, router, loginService, socket, layout) {
        this.route = route;
        this.router = router;
        this.loginService = loginService;
        this.socket = socket;
        this.layout = layout;
        this.toasterConfig = new ToasterConfig({
            showCloseButton: true,
            timeout: 10000,
            animation: 'flyRight',
        });
        this.mainNavbarToggled = false;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.loginService.isAuthenticated()) {
            this.socket.initSocket();
        }
        this.mainNavbarSubscription$ = this.layout.navbarToggle$.subscribe(function (toggle) {
            _this.mainNavbarToggled = toggle;
        });
        /*
        this.route.queryParams.subscribe((params: any) => {
          if (!_.isUndefined(params.doctorId) && !_.isUndefined(params.locations)) {
            if (!this.findChild('activate/:id', this.route)) {
              const redirectParams = JSON.stringify(params);
              this.router.navigate(['.'], {
                queryParams: {
                  redirect: '/#!/app/reservation/schedule/:doctorId?locations',
                  redirectParams: redirectParams,
                }
              });
            }
          }
        });
        */
        if (window.location.href.indexOf('www.') != -1)
            window.location.href = window.location.href.replace(/www./g, "");
        this.route.fragment.subscribe(function (fragment) {
            if (fragment && fragment.slice(0, 2) === '!/') {
                fragment = fragment.slice(1);
                // console.log('Našel fragment, ki izgleda kot star URL', fragment)
                var parsedFragment = urlParse(fragment, true);
                if (parsedFragment.query.redirect === 'tvw' || parsedFragment.query.redirectType === 'tvw') {
                    _this.router.navigate(['/'], { queryParams: parsedFragment.query });
                    return;
                }
                if (parsedFragment.pathname.slice(0, 5) === '/auth') {
                    if (parsedFragment.pathname.indexOf('/auth/login') !== -1) {
                        _this.router.navigate(['/'], { queryParams: parsedFragment.query });
                        return;
                    }
                }
                if (parsedFragment.pathname.slice(0, 8) === '/account') {
                    if (parsedFragment.pathname.indexOf('/account/register') !== -1) {
                        _this.router.navigate(['/account/register'], { queryParams: parsedFragment.query });
                        return;
                    }
                    if (parsedFragment.pathname.indexOf('/account/password') !== -1) {
                        _this.router.navigate(['/account/password'], { queryParams: parsedFragment.query });
                        return;
                    }
                    if (parsedFragment.pathname.indexOf('/account/activation-required') !== -1) {
                        _this.router.navigate(['/account/activation-required'], { queryParams: parsedFragment.query });
                        return;
                    }
                    if (parsedFragment.pathname.indexOf('/account/activate') !== -1) {
                        var path = parsedFragment.pathname;
                        var token = path.replace('/account/activate/', '');
                        _this.router.navigate(['/account/activate/' + token], { queryParams: parsedFragment.query });
                        return;
                    }
                    if (parsedFragment.pathname.indexOf('/account/recover-password') !== -1) {
                        var path = parsedFragment.pathname;
                        var token = path.replace('/account/recover-password/', '');
                        _this.router.navigate(['/account/recover-password/' + token], { queryParams: parsedFragment.query });
                        return;
                    }
                }
                if (parsedFragment.pathname.slice(0, 4) === '/app') {
                    if (parsedFragment.pathname.indexOf('/app/connected-accounts') !== -1) {
                        _this.router.navigate(['g/connected-accounts'], { queryParams: parsedFragment.query });
                        return;
                    }
                    if (parsedFragment.pathname.indexOf('/app/profile/') !== -1) {
                        _this.router.navigate(['g/account'], { queryParams: parsedFragment.query });
                        return;
                    }
                }
                if (parsedFragment.pathname.slice(0, 4) === '/app') {
                    if (parsedFragment.pathname.indexOf('/app/home') !== -1) {
                        _this.router.navigate(['g/dashboard'], { queryParams: parsedFragment.query });
                        return;
                    }
                    var doctorId = parsedFragment.pathname.slice(parsedFragment.pathname.lastIndexOf('/') + 1);
                    var query = __assign({ doctor: doctorId, location: parsedFragment.query.locations }, parsedFragment.query);
                    if (parsedFragment.pathname.indexOf('/app/reservation/schedule/') !== -1) {
                        _this.router.navigate(['/reservation-create'], { queryParams: query });
                        return;
                    }
                }
                // if (parsedFragment.pathname.indexOf('/messages/details/') !== -1) {
                //   this.router.navigate(['/messages/details'], {queryParams: parsedFragment.query});
                //   console.log(fragment)
                //   return
                // }
                //#!/app/reservation/schedule/52001?locations=00130&activity=SPLETNAR
                //#!/messages/details/199
                // window.location.href = environment.v1Url + '/#!' + fragment;
            }
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.mainNavbarSubscription$.unsubscribe();
    };
    AppComponent.prototype.findChild = function (needle, route) {
        if (route && route.routeConfig && route.routeConfig.path === 'activate/:id') {
            return true;
        }
        else if (route.firstChild) {
            return this.findChild(needle, route.firstChild);
        }
        else {
            return false;
        }
    };
    return AppComponent;
}());
export { AppComponent };
