var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DoctorsService } from "./../../../shared-services/doctors.service";
import { ChangeDetectorRef, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "./login.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import * as _ from "lodash";
import { environment } from "../../../../environments/environment";
import { StorageService } from "../../../shared-services/storage.service";
import { AccountService } from "../../../shared-services/account.service";
import { UtilsService } from "../../../shared-services/utils.service";
import swal from "sweetalert2";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(http, loginService, storage, router, toaster, route, account, utils, doctorsService, cd) {
        this.http = http;
        this.loginService = loginService;
        this.storage = storage;
        this.router = router;
        this.toaster = toaster;
        this.route = route;
        this.account = account;
        this.utils = utils;
        this.doctorsService = doctorsService;
        this.cd = cd;
        this.requestProcessing = false;
        this.emailNotVerified = false;
        this.authLogin = {
            username: "",
            password: "",
        };
        this.queryParams = {};
        this.isLandingPage = false;
        this.hidePassword = true;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLandingPage = this.router["url"].split("?")[0] == "/";
        this.route.queryParams.subscribe(function (params) {
            _this.queryParams = params;
            if (params.redirectParams)
                _this.authLogin.username = JSON.parse(params.redirectParams).login;
            if (params.login)
                _this.authLogin.username = params.login;
            if (params.pairingGuid && params.appDesc) {
                swal({
                    title: "Povezava z ustanovo",
                    html: "Za povezavo ra\u010Dunov <b>" + params.appDesc + "</b> in doZdravnika se prijavite ali registrirajte.\n          <br><br>\n          Prijava je mogo\u010Da v primeru, da ste obstoje\u010D uporabnik doZdravnika, v nasprotnem primeru se registrirajte.",
                    type: "warning",
                });
            }
        });
        var tmpUsername = this.storage.getShortCache("loginUsername");
        if (tmpUsername) {
            this.authLogin.username = tmpUsername;
        }
    };
    LoginComponent.prototype.updateCurrentEmail = function () {
        this.storage.setShortCache("loginUsername", this.authLogin.username);
    };
    LoginComponent.prototype.login = function (form) {
        var _this = this;
        this.requestProcessing = true;
        _.mapValues(form.controls, function (c) {
            c.markAsTouched();
        });
        if (form.invalid &&
            !(form["form"]["value"]["username"].length > 0 &&
                form["form"]["value"]["password"].length > 0)) {
            this.requestProcessing = false;
            swal({
                title: "Vnesite podatke v vsa polja",
                html: "Prosimo, vnesite svoj elektronski naslov ter geslo.",
                type: "error",
                confirmButtonText: "Zapri",
                confirmButtonClass: "btn btn-info btn-pill",
                showCloseButton: true,
            });
            return;
        }
        this.loginService
            .login(this.authLogin.username, this.authLogin.password)
            .subscribe(function (response) {
            _this.requestProcessing = false;
            _this.route.queryParams.subscribe(function (params) {
                var orgName = params.appDesc;
                if (params.pairingGuid && params.appDesc) {
                    swal({
                        title: "Povezava z ustanovo",
                        html: "S potrditvijo povezave boste ustanovi <b>" + orgName + "</b> omogo\u010Dili vpogled v informacije o datumih opravljenih pregledov in datumov ter zaporednih \u0161tevilk odmerkov cepljenja.\n              Ustanovi ne bo omogo\u010Den vpogled v nobene druge va\u0161e osebne podatke.",
                        type: "warning",
                        confirmButtonText: "Potrdi",
                        cancelButtonText: "Zavrni",
                        showCancelButton: true,
                    }).then(function (res) {
                        if (res.value) {
                            _this.loginService
                                .pairUser(params.pairingGuid, response)
                                .subscribe(function (response) {
                                swal({
                                    title: "Povezava uspešna",
                                    html: "1. korak uspe\u0161en<br>Uspe\u0161no ste dokon\u010Dali povezavo ra\u010Dunov med " + orgName + " in doZdravnika.<br><br>\n                    2. korak<br>\n                    Navodila za naro\u010Danje na sistematski pregled ali cepljenje<br><br>\n\n                    Za naro\u010Dilo na sistematski pregled ali cepljenje se ponovno prijavite v \u0161tudentski informacijski sistem in izberite<br>\n                    VIS -> Moji podatki -> doZdravnika-> 'PRIJAVI SE'<br><br>\n                    \n                    Za ogled celotnega procesa naro\u010Danja na sistematski pregled si lahko ogledate podrobna <a href=\"https://dozdravnika.si/help/vis\" target=\"_blank\">navodila</a>.",
                                    type: "success",
                                    allowOutsideClick: false,
                                }).then(function (res) {
                                    if (res.value) {
                                        _this.loginService.logout();
                                        _this.router.navigate(["/"]);
                                    }
                                });
                            }, function (err) {
                                swal({
                                    title: "Povezava ni uspela",
                                    html: "Ponovno poskusite kasneje",
                                    type: "error",
                                    allowOutsideClick: false,
                                });
                            });
                        }
                    });
                }
                var addDoctorID = undefined;
                if (params.addDoctor)
                    addDoctorID = params.addDoctor;
                if (params.redirectParams) {
                    var rParams = JSON.parse(params.redirectParams);
                    if (rParams.addDoctor)
                        addDoctorID = rParams.addDoctor;
                }
                if (addDoctorID) {
                    var doctor_1 = {
                        desc: "",
                        id: addDoctorID,
                        locations: [],
                        selectedId: null,
                        type: "Doctor",
                    };
                    _this.doctorsService
                        .getUserDoctors()
                        .subscribe(function (doctorsResponse) {
                        var existingDoctor = doctorsResponse.find(function (doc) { return doc.id == doctor_1.id; });
                        if (!existingDoctor) {
                            _this.doctorsService
                                .getDoctorByID(doctor_1)
                                .subscribe(function (doctorResponse) {
                                _this.doctorsService
                                    .addUserDoctors(doctorResponse)
                                    .subscribe(function () {
                                    _this.doParams(params);
                                    swal({
                                        title: "Izvajalec dodan",
                                        html: "Izvajalec je bil dodan med \"Moje izvajalce\".",
                                        type: "success",
                                        confirmButtonText: "Zapri",
                                        confirmButtonClass: "btn btn-info btn-pill",
                                        showCloseButton: true,
                                    }).then(function () {
                                        _this.cd.detectChanges();
                                    });
                                });
                            });
                        }
                        else
                            _this.doParams(params);
                    });
                }
                else {
                    _this.doParams(params);
                }
                // this.utils.getStaticConfig().subscribe((config) => {
                //   if (config.customPopupNotification) {
                //     const intr = setInterval(() => {
                //       if (!swal.isVisible()) {
                //         clearInterval(intr);
                //         swal({
                //           title: config.customPopupNotification.title,
                //           html: config.customPopupNotification.content,
                //           type: config.customPopupNotification.type
                //             ? config.customPopupNotification.type
                //             : "info",
                //           confirmButtonText: "Zapri",
                //           showCancelButton: false,
                //           reverseButtons: true,
                //           showCloseButton: true,
                //         });
                //       }
                //     }, 2000);
                //   }
                // });
            });
        }, function (error) {
            _this.requestProcessing = false;
            if (error.status === 400) {
                if (error.error.error == "user_not_activated")
                    swal({
                        title: "Prijava ni mogoča",
                        html: "Vaš račun ni aktiviran. Ponovno preverite svojo elektronsko pošto ali " +
                            '<a href="/help/nemozna-prijava"> kliknite za pomoč. </a>',
                        type: "error",
                        confirmButtonText: "Zapri",
                        confirmButtonClass: "btn btn-info btn-pill",
                        showCloseButton: true,
                    });
                else
                    swal({
                        title: "Naslov in geslo se ne ujemata",
                        html: "Vaš račun ali geslo je nepravilno. Če se ne spomnite svojega gesla, ga " +
                            '<a href="/account/password">  ponastavite zdaj. </a>',
                        type: "error",
                        confirmButtonText: "Zapri",
                        confirmButtonClass: "btn btn-info btn-pill",
                        showCloseButton: true,
                    });
            }
            if (error.status === 401 &&
                form["form"]["value"]["username"].indexOf("@") > 0) {
                swal({
                    title: "Naslov ni registriran",
                    html: "Elektronski naslov še ni registriran. <br/> Če ste se ravnokar registrirali, je potrebno pred prijavo v spletno rešitev doZdravnika potrditi elektronski naslov in aktivirati vaš uporabniški račun. <br/>" +
                        '<a href="/help">  Preberite pomoč </a> ali' +
                        '<a href="/account/register">  Ustvarite si nov račun </a>',
                    type: "error",
                    confirmButtonText: "Zapri",
                    confirmButtonClass: "btn btn-info btn-pill",
                    showCloseButton: true,
                });
            }
            if (error.status === 401 &&
                form["form"]["value"]["username"].indexOf("@") === -1) {
                swal({
                    title: "Naslov ni pravilen",
                    html: "Elektronski naslov, ki ste ga vnesli, ni napisan v pravilni obliki. <br/> Prosimo, ponovno vnesite elektronski naslov.",
                    type: "error",
                    confirmButtonText: "Zapri",
                    confirmButtonClass: "btn btn-info btn-pill",
                    showCloseButton: true,
                });
            }
            if (form["form"]["value"]["username"].length === 0 &&
                form["form"]["value"]["password"].length === 0) {
                swal({
                    title: "Vnesite podatke",
                    html: "Izpolnite vaš elektronski račun in geslo",
                    type: "error",
                    confirmButtonText: "Zapri",
                    confirmButtonClass: "btn btn-info btn-pill",
                    showCloseButton: true,
                });
            }
        });
    };
    LoginComponent.prototype.doParams = function (frozenParams) {
        var _this = this;
        var params = __assign({}, frozenParams);
        if (params.redirect || params.redirectType) {
            var redirectString = params.redirect;
            var redirectParams = {};
            if (params.redirectParams) {
                redirectParams = JSON.parse(params.redirectParams);
            }
            if (redirectString === "tvw" || params.redirectType === "tvw") {
                this.account
                    .getUser(this.authLogin.username)
                    .subscribe(function (userResponse) {
                    _this.storage.addKzzToCookies(userResponse.kzz);
                    window.location.href =
                        environment.tvwUrl +
                            "?token=" +
                            _this.storage.getTokenData().accessToken;
                });
            }
            else {
                delete redirectParams["login"];
                this.router.navigate([redirectString], {
                    queryParams: redirectParams,
                });
            }
        }
        else if (params.redirectParams) {
            var redirectParams = JSON.parse(params.redirectParams);
            if (redirectParams.redirectTo) {
                delete redirectParams["login"];
                this.router.navigate([redirectParams.redirectTo], {
                    queryParams: {},
                    replaceUrl: true,
                });
            }
            else if (this.isLandingPage) {
                delete params["login"];
                this.router.navigate(["g/dashboard"], {
                    queryParams: params,
                    replaceUrl: true,
                });
            }
        }
        else {
            if (this.isLandingPage) {
                delete params["login"];
                this.router.navigate(["g/dashboard"], {
                    queryParams: params,
                    replaceUrl: true,
                });
            }
            else {
                this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
                this.router.onSameUrlNavigation = "reload";
                var url = this.router["url"].split("?")[0];
                this.router.navigate([url], {
                    queryParams: params.s || params.code || params.question ? params : undefined,
                });
            }
        }
    };
    LoginComponent.prototype.rerouteToQR = function () {
        window.location.href = "https://app.infonet.si/dozdravnika_qr/";
    };
    return LoginComponent;
}());
export { LoginComponent };
