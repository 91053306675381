import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { UtilsService } from "./utils.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Log } from "../interfaces/log";
import { StorageService } from "./storage.service";
import * as moment from "moment";
import { MomentInput } from "moment";
import { Doctor } from "../interfaces/doctor";
import { Location } from "../interfaces/location";
import { of } from "rxjs";
import { Referral } from "../interfaces/referral";

@Injectable()
export class ReferralsService {
  constructor(
    public http: HttpClient,
    public utils: UtilsService,
    private storage: StorageService
  ) {}

  getReferrals(
    dateFrom: moment.Moment = moment("2020-01-01"),
    state: number = null
  ): Observable<HttpResponse<Referral[]>> {
    const userData = this.storage.getUserData();
    return this.http.get(
      this.utils.getPrescriptionsUrl() + "/getReferrals/" + userData.kzz,
      {
        observe: "response",
        params: {
          // state: state.toString(),
          dateFrom: dateFrom.format("YYYY-MM-DD"),
        },
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<HttpResponse<Referral[]>>;
  }

  cancelReferral(referralId, comment): Observable<any> {
    return this.http.post(
      this.utils.getPrescriptionsUrl() + "/cancelReferral/" + referralId,
      comment,
      { headers: this.utils.getHeader(this.storage.getTokenData()) }
    ) as Observable<any>;
  }

  /*
  removeReferral(referralId): Observable<any> {
    return this.http.post(this.utils.getPrescriptionsUrl() + '/closeReferral/' + referralId,
      {}) as Observable<any>;
  };
  */

  confirmReferral(doctor: Doctor, location: Location, comment: string = "") {
    const userData = this.storage.getUserData();
    return this.http.post(
      this.utils.getPrescriptionsUrl() + "/createReferral",
      {
        patientKzz: userData.kzz,
        institution: location.id,
        institutionName: location.desc,
        doctor: doctor.id,
        doctorName: doctor.desc,
        commentPatient: comment,
      },
      {
        observe: "response",
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<HttpResponse<Referral[]>>;
  }

  sendAttachment(
    referralId: number,
    filename: string,
    base64: string,
    desc: string = null
  ): Observable<Referral> {
    const token = this.storage.getTokenData();

    if (!desc) {
      desc = filename;
    }

    const body = new URLSearchParams();
    body.set("metadataDesc", desc);
    body.set("metadataFilename", filename);
    body.set("attachmentBase64Data", base64);

    return this.http.post(
      this.utils.getPrescriptionsUrl() + `/referral/${referralId}/attachment`,
      body.toString(),
      {
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    ) as any;
  }

  postAttachmentGuid(attachmentId: string) {
    const userData = this.storage.getUserData();
    const token = this.storage.getTokenData();
    return this.http.post(
      this.utils.getPrescriptionsUrl() +
        `/patient/${userData.kzz}/attachment/${attachmentId}/guid`,
      {},
      {
        headers: { Authorization: "Bearer " + token.accessToken },
      }
    ) as any;
  }

  getAttachmentContent(guid) {
    const userData = this.storage.getUserData();
    const token = this.storage.getTokenData();
    return this.http.get(
      `${this.utils.getPrescriptionsUrl()}/patient/${
        userData.kzz
      }/attachment/guid/${guid}/content`,
      {
        headers: { Authorization: "Bearer " + token.accessToken },
        responseType: "blob",
        //observe: 'response'
      }
    );
  }
}
