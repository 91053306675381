import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private navbarToggle: boolean = false;
  public navbarToggle$: BehaviorSubject<boolean> = new BehaviorSubject(this.navbarToggle);

  constructor() {
  }

  toggleNavbar(whichWay: boolean = null) {
    if (whichWay === null) {
      whichWay = !this.navbarToggle;
    }
    this.navbarToggle = whichWay;
    this.navbarToggle$.next(this.navbarToggle);
  }
}
