import {Injectable} from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {LoginService} from '../modules/shared/login/login.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url.indexOf('?') != -1 ? state.url.substr(0, state.url.indexOf('?')) : state.url;

    return this.checkLogin(url, state.root.queryParams);
  }


  checkLogin(url: string, queryParams: any): boolean {
    if (this.loginService.isAuthenticated()) {
      return true;
    }

    // Store the attempted URL for redirecting
    // this.loginService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate([''], {queryParams: {redirect: url, redirectParams: JSON.stringify(queryParams)}});
    return false;
  }
}
