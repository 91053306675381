import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from './utils.service';

@Injectable()
export class MailsService {

  constructor(
    public http: HttpClient,
    public utils: UtilsService,
  ) {
  }

  createReservationEmail(mail: any, reservationGUID: string) {
    return this.http.post(this.utils.getServerUrl() + '/reservation/mail/' + reservationGUID, mail);
  };

}
